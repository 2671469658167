import React from 'react'

const CountryFlag = ({className = '', country = 'fr', width = '16px'}) => {
  return (
    <React.Fragment>
      <img
        className={`${className}`}
        src={`https://lipis.github.io/flag-icon-css/flags/4x3/${country}.svg`}
        alt=''
      />

      <style jsx>
        {`
          img {
            width: ${width};
          }
        `}
      </style>
    </React.Fragment>
  )
}

export default CountryFlag
