import React from 'react'

import App from '../components/shared/app'
import Layout from '../components/shared/layout'
import CountryFlag from '../components/misc/country-flag'
import {string} from '../lib/utils'

const Page = (props) => {
  const {i18n, app} = props
  const {fallback, guess} = i18n
  const {zones} = app

  return (
    <App {...props}>
      <Layout bg=''>
        <div className='pt-5 text-center d-flex flex-column align-items-center justify-content-center'>
          <img
            src='https://res.cloudinary.com/inmemori/image/upload/CRM/img/logo-black.svg'
            className='mb-3'
            alt='inmemori.com'
            height='27px'
          />
          <div className=''>
            {zones
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((zone, i) => (
                <div key={i} className='text-left' onClick={() => {}}>
                  <a href={`https://${zone.lang}.inmemori.com`} className='btn btn-md text-primary'>
                    {string.capitalize(zone.name)}
                  </a>
                  <span className='ml-4 float-right' style={{marginTop: '4px'}}>
                    <CountryFlag country={zone.country} />
                  </span>
                </div>
              ))}
          </div>
          <small className='text-white'>
            fallback: {fallback ? 'true' : 'false'} - guess: {guess}
          </small>
        </div>

        <style jsx>
          {`
            li:hover {
              background: #eee;
            }
            .btn {
              font-size: 13px;
            }
          `}
        </style>
      </Layout>
    </App>
  )
}

Page.getInitialProps = async (ctx) => await App.getInitialProps(ctx)

export default Page
